<template>
  <div class="home">
    <el-container style="height:100%">
      <el-header>
        <header-layout></header-layout>
      </el-header>
      <el-main style="width: 100%; padding: 0; margin: auto">
        <!--        <el-carousel height="20rem" indicator-position="inside" style=" width: 100%">-->
        <!--          <el-carousel-item-->
        <!--            style="display: flex; align-items: center; color: #eeeeee; background-image: url('https://static.textualesg.com/nju.jpg'); background-size:cover;"-->

        <!--          >-->
        <!--            <div style="margin: 0 auto; text-align: left; width: 100%">-->
        <!--              &lt;!&ndash;              <div style="margin-left: 3%">&ndash;&gt;-->
        <!--              &lt;!&ndash;                <h1>TextualESG团队</h1>&ndash;&gt;-->
        <!--              &lt;!&ndash;                <h2>以热忱开发技术，以热情对待客户，以热血建设祖国</h2>&ndash;&gt;-->
        <!--              &lt;!&ndash;                <h3>永不止步，不断创新，迎难而上，精益求精</h3>&ndash;&gt;-->
        <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
        <!--            </div>-->
        <!--          </el-carousel-item>-->
        <!--          &lt;!&ndash;          <el-carousel-item style="">&ndash;&gt;-->

        <!--          &lt;!&ndash;          </el-carousel-item>&ndash;&gt;-->
        <!--        </el-carousel>-->
        <img src="https://static.textualesg.com/nju.jpg" style="width: 100%; height: auto"/>

        <div style="margin: 0 auto; background-color: #ffffff">
          <h1 style="padding: 1rem; margin: 0 auto;">团队介绍</h1>

          <AboutUs ref="aboutus"></AboutUs>
        </div>

        <div style="width: 90%; margin: 0 auto">
          <h1>
            项目介绍
          </h1>
          <h3>本产品为2021-2022年第十七届“花旗杯”金融创新应用大赛参赛作品。</h3>
          <p style="font-size: 1.2rem; text-align: left">
            TextualESG平台利用自然语言处理技术从上市公司社会责任报告、上市公司实地调研问答文本、上市公司新闻、分析师报告这四类文本中提取上市公司的ESG信息，
            并参考前沿学术文献中的方法，将文本ESG信息转化为关注度指标、情感指标等，最后计算得到我们的文本ESG评分，做到通过提取多源文本信息来弥补现存ESG评分体系的不足。
            除了提供ESG文本数据和ESG文本指标以外，Texual ESG平台还为具有不同投资理念的ESG投资者定制了个性化的投资策略，以及投资组合管理功能。
          </p>
        </div>

        <!--        <div style="background: white">-->
        <!--          <h1 style="padding: 1rem; margin: 0 auto; ">项目优势</h1>-->
        <!--          <el-row :gutter="0" style="width: 90%; margin: 0 auto; text-align: center">-->
        <!--            <el-col :span=colWidth v-for="(advantage, index) in advantages" :key="index">-->
        <!--              <div class="grid-content">-->
        <!--                <div style="padding: 30px">-->
        <!--                  <font-awesome-icon :icon=advantage.icon size="4x"/>-->
        <!--                  <h2>{{ advantage.title }}</h2>-->
        <!--                  <p style="text-align: left">{{ advantage.text }}</p>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </el-col>-->
        <!--          </el-row>-->
        <!--        </div>-->


      </el-main>
    </el-container>
    <FooterLayout></FooterLayout>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayout from '../../components/FooterLayout'
import AboutUs from '../../components/AboutUs'

export default {
  name: 'about',
  components: {
    AboutUs,
    FooterLayout,
    HeaderLayout
  },
  data() {
    return {
      activeNames: ['0', '1', '2', '3', '4'],
      advantages:
        [
          {
            icon: 'fa-solid fa-database',
            title: '更加广泛的数据来源',
            text: '不同于传统的ESG评价系统，我们的数据包括结构化、半结构化和非结构化的数据，特别是将多源文本包含在其中，因此具有独特的优势。传统的分析过程中，由于信息数量庞大且来源难以辨别，数据可能变得不可靠、质量低下且难以管理，但我们配备多源文本处理系统并进行科学化的处理、加工和交叉验证，以获取基于大规模信息的客观洞察。'
          },
          {
            icon: 'fa-solid fa-calculator',
            title: '更加科学的算法设置',
            text: '我们决定利用LDA主题模型分类，词种子方法分类建立词典，并且根据文献以及训练结果反复验证得出最佳的配比，最终再生成评分结果。这个过程比起随机的选择参数并且给出平均的参数进行计算具有相当大的优势。'
          },
          {
            icon: 'fa-solid fa-hands-clapping',
            title: '更加广阔的应用前景',
            text: '事实上，我们不仅给出一个抽象的结果，也可以将结果拆解成更多维度的实用信息，从而为不同主体带来更多参考价值。一旦我们对所有上市公司的ESG行为进行了更客观的评价，投资者就可以进行更绿色的投资，而政府也可以更好地进行监督。'
          }
        ],
      colWidth: 8
    }
  },
  methods: {
    calcSize(that) {
      if (window.innerWidth < 800) {
        that.colWidth = 24
      } else {
        that.colWidth = 8
      }
    }
  },
  mounted() {
    this.$refs.aboutus.activeNames = ['0', '1', '2', '3', '4']
    const that = this
    this.calcSize(this)
    window.onresize = () => {
      return (() => {
        that.calcSize(that)
      })()
    }
  }
}
</script>

<style>
.el-header {
  background-color: #34495E;
  color: #333;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

.el-footer {
  background-color: #2d2d2d;
}

.banner {
  background-size: 233%;
  animation: bg_anim 40s ease infinite;
  -webkit-animation: bg_anim 40s ease infinite;
  -moz-animation: bg_anim 40s ease infinite;
}

@keyframes bg_anim {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 20% 0%;
  }
  50% {
    background-position: 10% 10%;
  }
  75% {
    background-position: 20% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

h1 {
  font-size: 2em;
  font-weight: normal;
}

h2 {
  font-size: 1.75rem;
  font-weight: normal;
}

h3 {
  font-size: 1.4rem;
  font-weight: normal;
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
}

</style>
